@import "./infoStyle.css";
* {
	margin: 0;
	padding: 0;
	font-family: "Oswald", sans-serif;
}

// TODO @sam: Clean up css and make reusable helpers
// Let's be honest you probably aren't going to do this ^

@media (max-width: 1439px) {
	html {
		font-size: 13px;
	}
	.text {
		&-art {
			font-size: 7rem !important;
		}
	}
}
@media (max-width: 1023px) {
	html {
		font-size: 10px;
	}
}
@media (max-width: 767px) {
	html {
		font-size: 9px;
	}
}

html,
body {
	overflow: hidden;
}

.webgl {
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
}

@keyframes bounce {
	0% {
		margin-top: -200px;
	}
	45% {
		transform: scaleX(1.3);
	}
	50% {
		margin-top: 0px;
	}
	51% {
		transform: scaleX(1);
	}
	100% {
		margin-top: -200px;
	}
}

.loader {
	transition: opacity 1s linear;
}

.loader-start {
	opacity: 0;
	display: none;
	transition: opacity 0.5s linear;
	cursor: pointer;
}

.loading-screen {
	position: absolute;
	z-index: 1001;
	width: 100%;
	height: 100%;
	background-color: #121212;
}


.container-progress {
	color: white;
	font-size: 2rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.test {
	border: 2px solid red;
}
.load-progress {
	text-align: center;
	transition: 9s ease;
}
.load-text-container {
	overflow: hidden;
}

.load-bar {
	background-color: white;
	height: 8px;
	transform: scaleX(0);
	transition: transform 1.5s;
	width: 240px;
}
.load-text {
	font-size: 4rem;
	width: min-content;
}

.controls-overlay {
	position: absolute;
	bottom: 0;
	right: 0;
	color: white;
}
.back-btn {
	font-size: 1.5rem;
	margin: 1rem;
	padding: 0.5rem 2.5rem;
	z-index: 999;

	background-color: #121212;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	border-radius: 2rem;
	transition: 0.2s ease;
}
.back-btn:hover {
	cursor: pointer;
	background-color: #303030;
}
.enter-screen {
	position: absolute;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: #121212;
	color: white;
}

.control-text {
	font-weight: 300 !important;
}
.enter-controls {
	color: white;
	text-align: center;
	display: flex;
	gap: 1rem;
	margin-top: 12px;
}
.enter-controls > .key {
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 4px;
}
.key > p {
	text-align: center;
}
.divider {
	height: "100%";
	border: 2px solid grey;
}
.control-key-letter {
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	color: white;
}
.control-key {
	position: relative;
	border: 2px solid grey;
	border-radius: 0.5rem;
	width: 4rem;
	height: 4rem;
}
.enter-btn {
	font-size: 4rem;
	color: #484848;
	transition: 0.2s ease-in-out;
	text-align: center;
	border: 2px solid #484848;
}
.enter-btn:hover {
	color: white;
	border: 2px solid white;
}
.enter-header {
	font-size: 6rem;
	color: white;
	text-align: center;
}

/* HELPERS */
.txt-lg {
	font-size: 5rem;
	color: white;
}
.txt-md {
	font-size: 2rem;
	color: white;
}
.text-center {
	text-align: center;
}
.full-height {
	height: 100%;
}
.full-width {
	width: 100%;
}
.text-black {
	color: #000000;
}
.pt {
	padding-top: 1rem;
}
.text-white {
	color: white;
}
.clickable {
	cursor: pointer;
}
.row {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.items-center {
	align-items: center;
}
